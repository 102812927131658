<template>
  <a-drawer width="800" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>详情</b>
    </a-divider>
    <a-descriptions :column="1" v-if="form.picPath">
      <a-descriptions-item>
        <viewer :images="form.picPath ? form.picPath.split(',') : []">
          <img v-for="(item, key) in form.picPath ? form.picPath.split(',') : []" style="width:100px;height:100px;object-fit: cover;margin-right:5px" :src="item" :key="key">
        </viewer>
      </a-descriptions-item>
    </a-descriptions>
    <a-descriptions bordered :column="2" style="margin-bottom: 10px;">
      <a-descriptions-item label="标题" :span="2">{{ form.title }}</a-descriptions-item>
      <a-descriptions-item label="标题字体大小" :span="2">{{ form.titleFontSize }}</a-descriptions-item>
      <a-descriptions-item label="标题颜色">
        <a-tag v-if="form.titleColor == 0">白色</a-tag>
        <a-tag color="#ff0000" v-if="form.titleColor == 1">红色</a-tag>
        <a-tag color="#000000" v-if="form.titleColor == 2">黑色</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="标题粗体">
        <a-tag v-if="form.titleBold == 0">正常</a-tag>
        <a-tag v-if="form.titleBold == 1">粗体</a-tag>
      </a-descriptions-item>
    </a-descriptions>
    <a-descriptions bordered :column="2">
      <a-descriptions-item label="内容" :span="2">{{ form.content }}</a-descriptions-item>
      <a-descriptions-item label="内容字体大小" :span="2">{{ form.contentFontSize }}</a-descriptions-item>
      <a-descriptions-item label="内容颜色">
        <a-tag v-if="form.contentColor == 0">白色</a-tag>
        <a-tag color="#ff0000" v-if="form.contentColor == 1">红色</a-tag>
        <a-tag color="#000000" v-if="form.contentColor == 2">黑色</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="内容粗体">
        <a-tag v-if="form.contentBold == 0">正常</a-tag>
        <a-tag v-if="form.contentBold == 1">粗体</a-tag>
      </a-descriptions-item>
    </a-descriptions>
  </a-drawer>
</template>

<script>
import { getInformation } from '@/api/valve/messageAndPush'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        content: '',
        contentColor: undefined,
        remark: '',
        title: '',
        titleColor: undefined
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        // titleColor: [
        //   { required: true, message: '请选择标题颜色', trigger: ['blur', 'change'] }
        // ],
        // titleBold: [
        //   { required: true, message: '请选择标题粗体', trigger: ['blur', 'change'] }
        // ],
        // titleFontSize: [
        //   { required: true, message: '标题字体大小不能为空', trigger: 'blur' }
        // ],
        // title: [
        //   { required: true, message: '标题不能为空', trigger: 'blur' }
        // ],
        // contentColor: [
        //   { required: true, message: '请选择内容颜色', trigger: ['blur', 'change'] }
        // ],
        // contentBold: [
        //   { required: true, message: '请选择内容粗体', trigger: ['blur', 'change'] }
        // ],
        // contentFontSize: [
        //   { required: true, message: '内容字体大小不能为空', trigger: 'blur' }
        // ],
        // content: [
        //   { required: true, message: '内容不能为空', trigger: 'blur' }
        // ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        content: '',
        contentColor: undefined,
        remark: '',
        title: '',
        titleColor: undefined
      }
      // this.$nextTick(() => {
      //   this.$refs.form.resetFields()
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getInformation({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    }
  }
}
</script>
