<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="标题" prop="title">
                <a-input v-model="queryParam.title" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <!-- <a-col :md="6" :sm="24">
              <a-form-item label="消息编号" prop="number">
                <a-input v-model="queryParam.number" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col> -->
            <a-col :md="6" :sm="24">
              <a-form-item label="标题颜色" prop="titleColor">
                <a-select v-model="queryParam.titleColor" placeholder="请选择" allow-clear>
                  <a-select-option v-for="(item,key) in [{name:'红色',key:1},{name:'黑色',key:2}]" :key="key" :value="item.key">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="内容颜色" prop="contentColor">
                <a-select v-model="queryParam.contentColor" placeholder="请选择" allow-clear>
                  <a-select-option v-for="(item,key) in [{name:'红色',key:1},{name:'黑色',key:2}]" :key="key" :value="item.key">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tl:information:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['tl:information:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['tl:information:remove']">
          <a-icon type="delete" />删除
        </a-button>

        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 详情 -->
      <info
        ref="info"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="rotationAngle" slot-scope="text, record">
          <span v-if="record.rotationAngle == 0">横屏</span>
          <span v-if="record.rotationAngle == 1">竖屏</span>
        </span>
        <span slot="pic" slot-scope="text, record">
          <viewer :images="record.picPath ? record.picPath.split(',') : []">
            <img v-for="(item, key) in record.picPath ? record.picPath.split(',') : []" style="width:50px;height:50px;object-fit: cover;margin-right:5px" :src="item" :key="key">
          </viewer>
        </span>
        <span slot="titleBold" slot-scope="text, record">
          <a-tag v-if="record.titleBold == 0">正常</a-tag>
          <a-tag v-if="record.titleBold == 1">粗体</a-tag>
        </span>
        <span slot="titleColor" slot-scope="text, record">
          <a-tag v-if="record.titleColor == 0">白色</a-tag>
          <a-tag color="#ff0000" v-if="record.titleColor == 1">红色</a-tag>
          <a-tag color="#000000" v-if="record.titleColor == 2">黑色</a-tag>
        </span>
        <span slot="contentBold" slot-scope="text, record">
          <a-tag v-if="record.contentBold == 0">正常</a-tag>
          <a-tag v-if="record.contentBold == 1">粗体</a-tag>
        </span>
        <span slot="contentColor" slot-scope="text, record">
          <a-tag v-if="record.contentColor == 0">白色</a-tag>
          <a-tag color="#ff0000" v-if="record.contentColor == 1">红色</a-tag>
          <a-tag color="#000000" v-if="record.contentColor == 2">黑色</a-tag>
        </span>
        <span slot="content" slot-scope="text, record">
          <a-tooltip>
            <template #title>{{ record.content }}</template>
            <a>查看</a>
          </a-tooltip>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.info.handleUpdate(record, undefined)" v-hasPermi="['tl:information:query']">
            详情
          </a>
          <a-divider type="vertical" v-hasPermi="['tl:information:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tl:information:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tl:information:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tl:information:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"

        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageInformation, delInformation } from '@/api/valve/messageAndPush'
import CreateForm from './modules/CreateForm'
import info from './modules/info'
import { tableMixin } from '@/store/table-mixin'
import { addDateRange } from '@/utils/ruoyi'

export default {
  name: 'PushRecord',
  components: {
    CreateForm,
    info,
    addDateRange
},
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        title: null,
        number: null,
        titleColor: undefined,
        contentColor: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '编号',
          dataIndex: 'number',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '屏幕方向',
          dataIndex: 'rotationAngle',
          scopedSlots: { customRender: 'rotationAngle' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '图片',
        //   scopedSlots: { customRender: 'pic' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '标题',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '标题字体大小',
        //   dataIndex: 'titleFontSize',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '标题粗体',
        //   dataIndex: 'titleBold',
        //   scopedSlots: { customRender: 'titleBold' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '标题颜色',
          dataIndex: 'titleColor',
          scopedSlots: { customRender: 'titleColor' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '内容',
          dataIndex: 'content',
          scopedSlots: { customRender: 'content' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '内容字体大小',
        //   dataIndex: 'contentFontSize',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '内容粗体',
        //   dataIndex: 'contentBold',
        //   scopedSlots: { customRender: 'contentBold' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '内容颜色',
          dataIndex: 'contentColor',
          scopedSlots: { customRender: 'contentColor' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          width: 150,
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: 240,
          ellipsis: true,
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询型号信息列表 */
    getList () {
      this.loading = true
      pageInformation(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        name: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delInformation(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('valve/project/export', {
            ...that.queryParam
          }, `型号信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
